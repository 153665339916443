import { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AocList } from "./AocList";
import { ItemList } from "./ItemList";
import {
  findItemsByAppellatiopn,
  Item,
  getItemNames,
  Appellation,
  getMaker,
} from "../../data";
import type { SelectedItemInfo } from "../SelectedItemInfo";

type Props = {
  onSelect: (itemId: string, itemInfo: SelectedItemInfo) => unknown;
};

export const SelectFromAocButton = ({ onSelect }: Props) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedAppellation, setSelectedAppellation] = useState<Appellation>();

  const handleAocSelect = (appellation: Appellation) => {
    setSelectedAppellation(appellation);
    setStep(1);
  };

  const handleItemSelect = (item: Item) => {
    if (!selectedAppellation) return;

    setOpen(false);
    const { name, nameJa } = getItemNames(item);
    const maker = getMaker(item.maker);
    if (!maker) throw new Error("should not happen");
    onSelect(item.id, {
      name,
      nameJa,
      makerName: maker.name,
      makerNameJa: maker.name_ja,
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>AOCから検索</Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>
          <Box display="flex">
            <Box flex={1}>銘柄選択</Box>
            <Box>
              <Button onClick={() => setOpen(false)}>閉じる</Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {step === 0 && (
            <Box>
              <AocList onSelect={handleAocSelect} />
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Card>
                <CardContent>
                  <Box>{selectedAppellation?.name}</Box>
                  <Box>{selectedAppellation?.name_ja}</Box>
                  <Button onClick={() => setStep(0)}>AOCを選びなおす</Button>
                </CardContent>
              </Card>
              <Box my={2}>AOC {selectedAppellation?.name_ja}のワイン</Box>
              <ItemList
                items={
                  selectedAppellation
                    ? findItemsByAppellatiopn(selectedAppellation.id)
                    : []
                }
                onSelect={handleItemSelect}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
