import { useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { MakerList } from "./MakerList";
import { ItemList } from "./ItemList";
import { Maker, Item, getItemNames } from "../../data";
import type { SelectedItemInfo } from "../SelectedItemInfo";

type Props = {
  onSelect: (itemId: string, itemInfo: SelectedItemInfo) => unknown;
};

export const SelectFromMakerButton = ({ onSelect }: Props) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedMaker, setSelectedMaker] = useState<Maker>();

  const handleMakerSelect = (maker: Maker) => {
    setSelectedMaker(maker);
    setStep(1);
  };

  const handleItemSelect = (item: Item) => {
    if (!selectedMaker) return;

    setOpen(false);
    const { name, nameJa } = getItemNames(item);
    onSelect(item.id, {
      name,
      nameJa,
      makerName: selectedMaker.name,
      makerNameJa: selectedMaker.name_ja,
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>生産者から検索</Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
        <DialogTitle>
          <Box display="flex">
            <Box flex={1}>銘柄選択</Box>
            <Box>
              <Button onClick={() => setOpen(false)}>閉じる</Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {step === 0 && (
            <Box>
              <MakerList onSelect={handleMakerSelect} />
            </Box>
          )}
          {step === 1 && (
            <Box>
              <Card>
                <CardContent>
                  <Box>{selectedMaker?.name}</Box>
                  <Box>{selectedMaker?.name_ja}</Box>
                  <Button onClick={() => setStep(0)}>生産者を選びなおす</Button>
                </CardContent>
              </Card>
              <Box my={2}>{selectedMaker?.name_ja}のワイン</Box>
              <ItemList
                items={selectedMaker ? selectedMaker.lineup : []}
                onSelect={handleItemSelect}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
