import { ChangeEvent } from "react";
import { TextField, MenuItem } from "@mui/material";
import { getAppellation } from "data";

// 表示順をコントロールしたいので配列で固定する
const appellations = [
  "chambertin",
  "chambertin-clos-de-bèze",
  "chapelle-chambertin",
  "charmes-chambertin",
  "griotte-chambertin",
  "latricières-chambertin",
  "mazis-chambertin",
  "mazoyères-chambertin",
  "ruchottes-chambertin",
  "clos-de-la-roche",
  "clos-des-lambrays",
  "clos-de-tart",
  "clos-saint-denis",
  "bonnes-mares",
  "musigny",
  "clos-de-vougeot",
  "échezeaux",
  "grands-échezeaux",
  "la-grande-rue",
  "la-romanée",
  "la-tâche",
  "richebourg",
  "romanée-conti",
  "romanée-saint-vivant",
].map((id) => getAppellation(id));

type Props = {
  value: string;
  onChange: (appellationId: string) => void;
};

const GrandCruSelector = ({ value, onChange }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (newValue === "-") newValue = "";
    onChange(newValue);
  };

  return (
    <TextField
      label="AOC"
      select
      fullWidth
      margin="dense"
      size="small"
      value={value || "-"}
      onChange={handleChange}
    >
      <MenuItem key="not-selected" value="-">
        選択してください
      </MenuItem>
      {appellations.map((appellation) => {
        if (appellation == null) throw new Error(); // should not occur

        return (
          <MenuItem key={appellation.id} value={appellation.id}>
            {appellation.name_ja}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default GrandCruSelector;
