import { Box } from "@mui/material";
import { ExternalLink } from "components/ExternalLink";

export interface ResultItem {
  affiliateUrl: string;
  availability: 0 | 1; // 0：販売不可能, 1：販売可能
  catchcopy: string;
  creditCardFlag: 0 | 1; // 0：カード利用不可, 1：カード利用可
  genreId: string;
  imageFlag: 0 | 1; // 0：商品画像無し, 1：商品画像有り
  itemCaption: string;
  itemCode: string;
  itemName: string;
  itemPrice: number;
  itemUrl: string;
  postageFlag: 0 | 1; // 0：送料込, 1：送料別
  shopAffiliateUrl: string;
  shopCode: string;
  shopName: string;
  shopOfTheYearFlag: 0 | 1; // 0：ショップオブザイヤー未受賞店舗, 1：ショップオブザイヤー受賞店舗
  shopUrl: string;
  mediumImageUrls: string[];
  smallImageUrls: string[];
  tagIds: number[];
  taxFlag: 0 | 1; //0：税込, 1：税別
}

type Props = {
  items: ResultItem[] | null;
};

export const ResultsView = ({ items }: Props): JSX.Element | null => {
  if (items == null) return null;

  if (items.length === 0) {
    return (
      <Box m={4} textAlign="center">
        該当する商品はありません。
      </Box>
    );
  }

  return (
    <Box>
      {items.map((item, index) => {
        const {
          itemCode,
          shopAffiliateUrl,
          affiliateUrl,
          shopName,
          itemName,
          itemPrice,
          taxFlag,
          postageFlag,
          mediumImageUrls,
        } = item;
        const imageUrl =
          mediumImageUrls && mediumImageUrls.length > 0 && mediumImageUrls[0];

        return (
          <Box
            key={index}
            display="flex"
            flexWrap="wrap"
            my={4}
            pb={2}
            borderBottom="1px solid #ddd"
          >
            <Box minWidth={180} textAlign="center">
              {imageUrl && (
                <ExternalLink href={affiliateUrl}>
                  <img src={imageUrl} alt={itemName} />
                </ExternalLink>
              )}
            </Box>
            <Box
              flex={1}
              minWidth={240}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box fontSize="0.8rem">
                <ExternalLink href={shopAffiliateUrl}>{shopName}</ExternalLink>
              </Box>
              <Box fontWeight="bold" mt={1}>
                <ExternalLink href={affiliateUrl}>{itemName}</ExternalLink>
              </Box>
              <Box mt={1}>
                <Box component="span" fontSize="1.2rem" fontWeight="bold">
                  {itemPrice.toLocaleString()} 円
                </Box>
                <Box component="span" fontSize="0.8rem" fontWeight="bold">
                  {taxFlag ? " + 税" : ""}
                  {postageFlag ? " + 送料" : " (送料込)"}
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
