import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import GrandCruSelector from "./GrandCruSelector";
import PriceRangeSelector, { PriceRange } from "./PriceRangeSelector";
import { ResultsView, ResultItem } from "components/ResultsView";

const SearchByPriceRange = () => {
  const [appellationId, setAppellationId] = useState("");
  const [priceRange, setPriceRange] = useState<{
    minPrice: number;
    maxPrice: number | null;
  }>({
    minPrice: 10000,
    maxPrice: 14999,
  });
  const [items, setItems] = useState<ResultItem[]>();

  const handleClick = async () => {
    const { minPrice, maxPrice } = priceRange;
    const url = `https://rudcxzwhsb.execute-api.ap-northeast-1.amazonaws.com/latest/search2?appellationId=${appellationId}&minPrice=${minPrice}&maxPrice=${
      maxPrice ?? ""
    }`;

    const res = await fetch(url);
    const results = await res.json();

    if (results.errorMessage) throw new Error(results.errorMessage);

    setItems(results.items);
  };

  return (
    <div>
      <GrandCruSelector value={appellationId} onChange={setAppellationId} />
      <PriceRangeSelector value={priceRange} onChange={setPriceRange} />
      <Box mt={2}>
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={!appellationId}
          fullWidth
        >
          検索
        </Button>
      </Box>
      <Box mt={1}>
        <Typography variant="body2">
          ※　検索結果はリアルタイムではありません（最大24時間遅れ）
        </Typography>
      </Box>
      {items != null && <ResultsView items={items} />}
    </div>
  );
};

export default SearchByPriceRange;
