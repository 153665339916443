import { List } from "components/List";
import { ListItem } from "components/ListItem";
import { Item, getItemNames } from "data";
import { useSearchContext } from "../SearchContext";

type Props = {
  items: Item[];
  onSelect: (item: Item) => unknown;
};

export const ItemList = ({ items, onSelect }: Props) => {
  const { stats } = useSearchContext();

  return (
    <List>
      {items.map((item) => {
        const { name, nameJa } = getItemNames(item);

        return (
          <ListItem
            key={item.id}
            primaryText={name}
            secondaryText={nameJa}
            itemCount={stats[item.maker]?.[item.key]}
            onClick={() => onSelect(item)}
          />
        );
      })}
    </List>
  );
};
