import makers_data from "./generated/makers_data";
import appellations_data from "./generated/appellations_data";

export type Item = {
  key: string; // {makerId}.{key} で一意の銘柄を表すidになる
  id: string;
  appellation: string;
  name?: string;
  name_ja?: string;
  maker: string; // maker id
};
export type Maker = {
  id: string;
  name: string;
  name_ja: string;
  lineup: Item[];
};
export type Climat = {
  id: string;
  name: string;
  alt_name?: string;
  name_ja?: string;
  alt_name_ja?: string;
};
export type Appellation = {
  id: string;
  name: string;
  name_ja: string;
  climats?: Climat[];
  class: string;
};

export const makers: Maker[] = makers_data
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((maker) => {
    return {
      ...maker,
      lineup: maker.lineup.map((item) => ({
        ...item,
        maker: maker.id,
        id: `${maker.id}.${item.key}`,
      })),
    };
  });
export const appellations: Appellation[] = appellations_data;

export function getMaker(id: string): Maker | undefined {
  return makers.find((maker) => maker.id === id);
}

export function getAppellation(
  appellationKeyOrId: string
): Appellation | undefined {
  const [idAndClimat] = appellationKeyOrId.split("$");
  const [appellationId] = idAndClimat.split(":");

  return appellations.find((appellation) => appellation.id === appellationId);
}

export function getAppellationAndClimat(appellationKey: string): {
  appellation: Appellation | undefined;
  climat: Climat | undefined;
  type: string;
} {
  const [idAndClimat, type] = appellationKey.split("$");
  const [appellationId, climatId] = idAndClimat.split(":");

  const appellation = appellations.find(
    (appellation) => appellation.id === appellationId
  );

  const climat =
    appellation && climatId
      ? appellation.climats?.find((climat: Climat) => climat.id === climatId)
      : undefined;

  return {
    appellation,
    climat,
    type,
  };
}

export function splitAppellationKey(appellationKey: string): {
  appellationId: string;
  climatId: string;
  type: string;
} {
  const [idAndClimat, type] = appellationKey.split("$");
  const [appellationId, climatId] = idAndClimat.split(":");
  return {
    appellationId,
    climatId,
    type,
  };
}

export function findItemsByAppellatiopn(
  appellationId: string,
  climatId?: string,
  type?: string
): Item[] {
  // makerのlineupの中から該当するものを探す

  const items: Item[] = [];

  makers.forEach((maker) => {
    items.push(
      ...maker.lineup.filter((item) => {
        const target = splitAppellationKey(item.appellation);

        if (target.appellationId !== appellationId) return false;
        if (climatId && target.climatId !== climatId) return false;
        if (type && target.type !== type) return false;

        return true;
      })
    );
  });

  return items;
}

export function getItemNames(item: Item): { name: string; nameJa: string } {
  let name = item.name;
  let nameJa = item.name_ja;

  if (name == null || nameJa == null) {
    const { appellation, climat, type } = getAppellationAndClimat(
      item.appellation
    );

    if (appellation == null) {
      throw new Error("Invalid data");
    }

    if (name == null) {
      name = appellation.name;
      if (climat) name += " " + climat.name;
      if (type === "rouge") name += " Rouge";
      if (type === "blanc") name += " Blanc";
    }

    if (nameJa == null) {
      nameJa = appellation.name_ja;
      if (climat?.name_ja) nameJa += " " + climat.name_ja;
      if (type === "rouge") nameJa += " ルージュ";
      if (type === "blanc") nameJa += " ブラン";
    }
  }

  return { name, nameJa };
}
