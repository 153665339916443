import { useState, useEffect, SyntheticEvent } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Container, Tabs, Tab, Box } from "@mui/material";
import PinpointSearch from "./PinpointSearch";
import SearchByPriceRange from "./SearchByPriceRange";
import { useSearchParams, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const tabValue = Number(searchParams.get("t") || "0");

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSearchParams({ t: "" + newValue });
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="ピンポイント検索" {...a11yProps(0)} />
            <Tab label="グラン・クリュ検索" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box py={4}>
          <Box
            role="tabpanel"
            hidden={tabValue !== 0}
            id={`search-mode-tabpanel-0`}
            aria-labelledby={`search-mode-tab-0`}
          >
            <PinpointSearch />
          </Box>
          <Box
            role="tabpanel"
            hidden={tabValue !== 1}
            id={`search-mode-tabpanel-1`}
            aria-labelledby={`search-mode-tab-1`}
          >
            <SearchByPriceRange />
          </Box>
        </Box>
      </Container>
    </>
  );
}

function a11yProps(index: number) {
  return {
    id: `search-mode-tab-${index}`,
    "aria-controls": `search-mode-tabpanel-${index}`,
  };
}

export default App;
