import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
} from "@mui/material";
import { ResultsView, ResultItem } from "components/ResultsView";
import { SearchContext, Stats } from "./SearchContext";
import { SelectFromMakerButton } from "./SelectFromMakerButton";
import { SelectFromAocButton } from "./SelectFromAocButton";
import type { SelectedItemInfo } from "./SelectedItemInfo";

type SearchCondition = {
  itemId: string;
  page: number;
  vintage: number | null;
};

const PinpointSearch = () => {
  const [stats, setStats] = useState<Stats>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [items, setItems] = useState<ResultItem[]>();
  const [searchCondition, setSearchCondition] = useState<SearchCondition>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [selectedItem, setSelectedItem] = useState<SelectedItemInfo>();

  useEffect(() => {
    const fn = async () => {
      const res = await fetch(
        "https://rudcxzwhsb.execute-api.ap-northeast-1.amazonaws.com/latest/stats"
      );
      const results = await res.json();
      setStats(results);
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      if (searchCondition) {
        try {
          setLoading(true);
          setError(undefined);

          const res = await fetch(buildSearchUrl(searchCondition));
          const results = await res.json();

          if (results.errorMessage) throw new Error(results.errorMessage);

          setItems([...(items ?? []), ...results.items]);
          setPageCount(results.pageCount);
        } catch (err: unknown) {
          setError(err instanceof Error ? err : new Error("unknown error"));
        } finally {
          setLoading(false);
        }
      }
    };

    fn();
  }, [searchCondition]); // warningが出るがここにitemsを含めると無弁ループになる。後でちゃんと考える

  const handleSelect = (itemId: string, item: SelectedItemInfo) => {
    setSearchCondition({
      itemId,
      page: 1,
      vintage: null,
    });
    setSelectedItem(item);
    setCurrentPage(1);
    setItems(undefined);
  };

  const handleLoadMore = () => {
    if (searchCondition == null) return;

    setCurrentPage(currentPage + 1);
    setSearchCondition({
      ...searchCondition,
      page: searchCondition.page + 1,
    });
  };

  return (
    <SearchContext.Provider value={{ stats }}>
      <Box>
        <SelectFromMakerButton onSelect={handleSelect} />
        <SelectFromAocButton onSelect={handleSelect} />
      </Box>
      {selectedItem && (
        <Box mt={2}>
          <Typography variant="h6">
            {selectedItem.makerNameJa} / {selectedItem.nameJa}
          </Typography>
          <Typography variant="caption">
            {selectedItem.makerName} / {selectedItem.name}
          </Typography>
        </Box>
      )}
      <Box my={2}>
        {items != null && <ResultsView items={items} />}
        {error && <Alert severity="error">エラーが発生しました。</Alert>}
        {loading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box>
        {currentPage < pageCount && (
          <Button onClick={handleLoadMore}>もっと見る</Button>
        )}
      </Box>
    </SearchContext.Provider>
  );
};

function buildSearchUrl({ itemId, page, vintage }: SearchCondition): string {
  const PREFIX =
    "https://rudcxzwhsb.execute-api.ap-northeast-1.amazonaws.com/latest/search";

  const params = new URLSearchParams();

  params.append("item", itemId);
  params.append("page", page.toString());
  if (vintage != null) params.append("vintage", vintage.toString());

  return `${PREFIX}?${params.toString()}`;
}

export default PinpointSearch;
