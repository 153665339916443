import { ChangeEvent } from "react";
import { TextField, MenuItem } from "@mui/material";

export type PriceRange = {
  minPrice: number;
  maxPrice: number | null;
};

type Props = {
  value: PriceRange;
  onChange: (value: PriceRange) => void;
};

const PriceRangeSelector = ({ value, onChange }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const match = /(\d+)-(\d+)?/.exec(e.target.value);
    if (match) {
      const [, minPrice, maxPrice] = match;
      onChange({
        minPrice: Number(minPrice),
        maxPrice: maxPrice ? Number(maxPrice) : null,
      });
    }
  };

  return (
    <TextField
      label="価格帯"
      select
      margin="dense"
      size="small"
      value={`${value.minPrice}-${value.maxPrice ?? ""}`}
      onChange={handleChange}
    >
      <MenuItem value="10000-14999">10,000 〜 14,999 円</MenuItem>
      <MenuItem value="15000-19999">15,000 〜 19,999 円</MenuItem>
      <MenuItem value="20000-29999">20,000 〜 29,999 円</MenuItem>
      <MenuItem value="30000-49999">30,000 〜 49,999 円</MenuItem>
      <MenuItem value="50000-99999">50,000 〜 99,999 円</MenuItem>
      <MenuItem value="100000-199999">100,000 〜 199,999 円</MenuItem>
      <MenuItem value="200000-499999">200,000 〜 499,999 円</MenuItem>
      <MenuItem value="500000-999999">500,000 〜 999,999 円</MenuItem>
      <MenuItem value="1000000-">1,000,000 円以上</MenuItem>
    </TextField>
  );
};

export default PriceRangeSelector;
