import { Box } from "@mui/material";
import { List } from "components/List";
import { ListItem } from "components/ListItem";
import { getAppellation, Appellation } from "../../data";

type Props = {
  onSelect: (appellation: Appellation) => unknown;
};

// 表示順をコントロールしたいので配列で固定する
const appellations = [
  "bourgogne",
  "bourgogne-aligoté",
  "bourgogne-gamay",
  "bourgogne-passe-tout-grains",
  "crémant-de-bourgogne",
  "bourgogne-côte-d-or",
  "bourgogne-hautes-côtes-de-nuits",
  "bourgogne-grand-ordinaire",
  "bourgogne-ordinaire",
  "côte-de-nuits-villages",
  "marsannay",
  "fixin",
  "fixin-1er-cru",
  "gevrey-chambertin",
  "gevrey-chambertin-1er-cru",
  "chambertin",
  "chambertin-clos-de-bèze",
  "chapelle-chambertin",
  "charmes-chambertin",
  "griotte-chambertin",
  "latricières-chambertin",
  "mazis-chambertin",
  "mazoyères-chambertin",
  "ruchottes-chambertin",
  "morey-saint-denis",
  "morey-saint-denis-1er-cru",
  "clos-de-la-roche",
  "clos-des-lambrays",
  "clos-de-tart",
  "clos-saint-denis",
  "bonnes-mares",
  "chambolle-musigny",
  "chambolle-musigny-1er-cru",
  "bonnes-mares",
  "musigny",
  "vougeot",
  "vougeot-1er-cru",
  "clos-de-vougeot",
  "vosne-romanée",
  "vosne-romanée-1er-cru",
  "échezeaux",
  "grands-échezeaux",
  "la-grande-rue",
  "la-romanée",
  "la-tâche",
  "richebourg",
  "romanée-conti",
  "romanée-saint-vivant",
  "nuits-saint-georges",
  "nuits-saint-georges-1er-cru",
].map((id) => getAppellation(id));

export const AocList = ({ onSelect }: Props) => {
  return (
    <List>
      {appellations.map((app, index) => {
        if (app == null) throw new Error(); // should not occur

        // 現状銘柄データがないものはクリックできないようにする

        let indent = 0;
        switch (app.class) {
          case "1er-cru":
            indent = 1;
            break;
          case "grand-cru":
            indent = 2;
            break;
        }

        return (
          <Box pl={indent * 2} key={index}>
            <ListItem
              primaryText={app.name}
              secondaryText={app.name_ja}
              onClick={() => onSelect(app)}
            />
          </Box>
        );
      })}
    </List>
  );
};
