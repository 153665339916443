import { List } from "components/List";
import { ListItem } from "components/ListItem";
import { Item, getItemNames, getMaker } from "data";
import { useSearchContext } from "../SearchContext";

type Props = {
  items: Item[];
  onSelect: (item: Item) => unknown;
};

export const ItemList = ({ items, onSelect }: Props) => {
  const { stats } = useSearchContext();

  return (
    <List>
      {items.map((item) => {
        const { name, nameJa } = getItemNames(item);
        const maker = getMaker(item.maker);
        if (maker == null) throw new Error(); // should not occur

        return (
          <ListItem
            key={item.id}
            primaryText={name + " / " + maker.name}
            secondaryText={nameJa + " / " + maker.name_ja}
            itemCount={stats[maker.id]?.[item.key]}
            onClick={() => onSelect(item)}
          />
        );
      })}
    </List>
  );
};
