import { ReactNode } from "react";
import { Link } from "@mui/material";

type Props = {
  href: string;
  children: ReactNode;
};

export const ExternalLink = ({ href, children }: Props): JSX.Element => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer" color="inherit">
      {children}
    </Link>
  );
};
