import { List } from "components/List";
import { ListItem } from "components/ListItem";
import { makers, Maker } from "data";

type Props = {
  onSelect: (maker: Maker) => unknown;
};

export const MakerList = ({ onSelect }: Props) => {
  return (
    <List>
      {makers.map((maker) => {
        return (
          <ListItem
            key={maker.id}
            primaryText={maker.name}
            secondaryText={maker.name_ja}
            onClick={() => onSelect(maker)}
          />
        );
      })}
    </List>
  );
};
