import {
  ListItem as MuiListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Badge,
} from "@mui/material";

type Props = {
  primaryText: string;
  secondaryText?: string;
  itemCount?: number | null;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
};

export const ListItem = ({
  primaryText,
  secondaryText,
  itemCount,
  onClick,
}: Props): JSX.Element => {
  return (
    <MuiListItem onClick={onClick} disablePadding>
      <ListItemButton>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        <ListItemAvatar>
          <Badge
            badgeContent={itemCount}
            showZero
            max={30}
            color="primary"
            style={{ left: 24 }}
          />
        </ListItemAvatar>
      </ListItemButton>
    </MuiListItem>
  );
};
