import { createContext, useContext } from "react";

export type Stats = {
  [makerId: string]: {
    [itemKey: string]: number;
  };
};

export const SearchContext = createContext<{
  stats: Stats;
}>({ stats: {} });

export const useSearchContext = () => {
  return useContext(SearchContext);
};
